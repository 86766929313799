import { NavLink } from 'react-router-dom';
import ISubcategory from '../../interfaces/ISubcategory';

const ItemLink = ({
  title,
  to,
  onMouseEnterInner,
  onMouseLeaveInner,
}: {
  title: string;
  to: string | undefined;
  subcategories?: ISubcategory[] | undefined;
  onMouseEnterInner?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeaveInner?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}) => {
  return (
    <li className="list-item">
      <NavLink
        className="nav-link"
        to={to ? to : ''}
        onMouseEnter={onMouseEnterInner}
        onMouseLeave={onMouseLeaveInner}
      >
        {title}
      </NavLink>
    </li>
  );
};
export default ItemLink;
