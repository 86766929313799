import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import handleResize from '../../utils/handleResize';

import Layout from '../Layout';
import SliderNews from './SliderNews';
import SliderNewsMobile from './SliderNewsMobile';

export default function News() {
  const navigator = useNavigate();
  const [mobile, setMobile] = useState(false);
  useEffect(() => handleResize(setMobile), []);
  return (
    <section className="news">
      <Layout className="layout" label="Новости" onClick={() => navigator('../news')} />
      {!mobile && <SliderNews />}
      {mobile && <SliderNewsMobile />}
    </section>
  );
}
