import IProduct from '../interfaces/IProduct';

const getLinkedElements = (
  query: { [key: string]: string },
  key: string,
  param: string | number,
  arr: { [key: string]: string }[],
  arrKey: string | number
) => {
  const res = arr.filter((el) => el[arrKey] === param);
  return {
    ...query,
    key: res,
  };
};
const getProductNav = (arr: IProduct[], id: string) => {
  const res: (IProduct | string)[] = [];
  arr.forEach((el, index) => {
    if (el.id === +id) {
      res.push(arr[index - 1] || 'Предыдущего товара нет');
      res.push(arr[index + 1] || 'Следующего товара нет');
    }
  });
  return res;
};
const filterProducts = (products: IProduct[], key: string, val: string) =>
  products.filter((product) => product[key] === val);

const getLengthUntilSpace = (str: string, max: number) => {
  if (!str || !max) return;
  let length = max;
  let elem = str[length];
  if (str.length <= max) return length;
  while (elem !== ' ') {
    if (str.length <= length) break;
    elem = str[length];
    length++;
  }
  return length;
};
export { getLinkedElements, getProductNav, filterProducts, getLengthUntilSpace };
