import { Fragment } from 'react';

export default ({ email }: { email: string }) => {
  return (
    <Fragment>
      <h4>Контакты</h4>
      <p>194292, Санкт-Петербург, 2-й Верхний переулок, д.4, корп.1, Лит. А, оф. 78.</p>
      <b>Тел.:</b> <a href="tel: +7 800 44 400 82"> 8 (800) 44-400-82 </a> <br />
      <b>Моб.:</b> <a href="tel: +7 921 996-60-44"> 8 (921) 996-60-44 </a>
      <br />
      <b>Email:</b> <a href={`mailto:${email}`}> {email} </a>
      <br />
    </Fragment>
  );
};
