import { NavLink } from 'react-router-dom';
import background from '../../assets/background-image.jpeg';
import './Breadcrumbs.css';

export default function Breadcrumbs({ location }: { location: { [key: string]: string }[] }) {
  return (
    <section className="layout-background">
      <div className="banner-title">
        {location.map(({ title, to }, index) => (
          <NavLink key={`banner-${index}`} to={to}>
            <h1> {title} &nbsp;</h1>
          </NavLink>
        ))}
      </div>
      <img src={background} alt={'поставки промышленного оборудования'} />
    </section>
  );
}
