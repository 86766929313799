import { useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import INews from '../../interfaces/INews';
import getPath from '../../utils/getPath';
import ButtonMore from '../ButtonMore/ButtonMore';
import './ItemContent.css';
interface IData {
  id: string;
  images: string;
  title: string;
  description: string;
}
export default function ({
  data,
  className,
  section,
}: {
  data: IData[] | INews[];
  section: string;
  className?: string;
}) {
  const [imgIsLoaded, setLoaded] = useState(false);
  return (
    <div className={`${className ? className : ''} container-item-content`}>
      {data.map(({ images, title, description, id }, index) => (
        <div key={`miniature-${index}`} className="item-content-miniature">
          <div className="img-container">
            <img
              style={imgIsLoaded ? { display: 'block' } : { display: 'none' }}
              src={images}
              onLoad={() => setLoaded(true)}
            />
            {!imgIsLoaded && <Spinner />}
          </div>
          <div className="item-content-description">
            <h1>{title}</h1>
            <p>{description}</p>
            <ButtonMore className="item-content-button" to={`${getPath(section, title)}-${id}`} />
          </div>
        </div>
      ))}
    </div>
  );
}
