import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import industries from '../../assets/data/industries/industries';
import AsideMenu from '../../components/AsideMenu/AsideMenu';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import ImagePreload from '../../components/Image/ImagePreload';
import url from '../../constants/host';
import IProduct from '../../interfaces/IProduct';
import { RootState } from '../../store/store';
import getPathProduct from '../../utils/getPathProduct';
import handleResize from '../../utils/handleResize';
import './IndustryPage.css';
import SEO from '../../components/Seo/Seo';

export default () => {
  const navigator = useNavigate();
  const { industryName } = useParams() || '';
  const currId = industryName?.split('-')[industryName.split('-').length - 1];
  const currIndustry = industries.links.filter(({ id }) => id == currId)[0];
  const productsRedux = useSelector((state: RootState) => state.products.products);
  const [currentProducts, setCurrentProducts] = useState<IProduct[]>([]);
  const [mobile, setMobile] = useState(false);

  useEffect(() => handleResize(setMobile), []);
  useEffect(() => {
    if (!currIndustry) {
      navigator('/page404');
    } else {
      const currentProducts = productsRedux.filter((product) =>
        product.use.includes(currIndustry.title.split(' ').slice(0, 2).join(' '))
      );
      setCurrentProducts(currentProducts);
    }
  }, [currIndustry, navigator, productsRedux]);

  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Отрасли /', to: '/otrasli' },
    { title: `${currIndustry?.title}`, to: '' },
  ];
  const tags = {
    title: `Датчики и другое измерительное оборудование в отрасли "${currIndustry?.title}"`,
    description: `${currIndustry?.title}. Различное контрольно-измерительное оборудование: датчики, аксессуары`,
    canonical: window.location.href,
  };
  const og = {
    title: tags.title,
    description: tags.description,
    image: currIndustry?.images,
    url: tags.canonical,
  };
  return (
    <>
      <SEO tags={tags} og={og} />
      <Container location={location} className=" industry-page-container">
        <>{!mobile && <AsideMenu />}</>
        <div>
          <div className="industry-image-container">
            <ImagePreload src={currIndustry?.images} alt={currIndustry?.title} />
          </div>
          <div className="industry-card-container">
            {currentProducts.map(({ id, img, title }) => (
              <Card
                key={id}
                src={`${url}${img.split(';')[0]}`}
                alt={title}
                className={'card-mini'}
                onClick={() => {
                  const productName = getPathProduct(title);
                  navigator(`/product/${productName}/id/${id}`);
                }}
                captionActive={true}
              />
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};
