import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSearchQuery, setSearchResults } from '../../store/productSlice';
import { RootState } from '../../store/store';
import search from '../../utils/search';

import './SearchBar.css';

const SearchBar = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state: RootState) => state.products.products);
  const [word, setWord] = useState('');
  return (
    <form
      className="form-search"
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(setSearchResults(search(products, word)));
        dispatch(setSearchQuery(word));
        navigator(`../search/${word}`);
      }}
    >
      <input
        className="search-bar"
        type="search"
        placeholder="Поиск"
        onChange={(event) => setWord(event.target.value)}
      />
      <button className="search-button" type="submit"></button>
    </form>
  );
};
export default SearchBar;
