import usePagination from './usePagination';
import url from '../../constants/host';
import IProduct from '../../interfaces/IProduct';
import Card from '../Card/Card';
import './Pagination.css';
import getPathProduct from '../../utils/getPathProduct';
import { useNavigate } from 'react-router-dom';

export default function Pagination({ products }: { products: IProduct[] }) {
  const navigator = useNavigate();
  const { firstContentIndex, lastContentIndex, nextPage, prevPage, page, setPage, totalPages } =
    usePagination({
      contentPerPage: 12,
      count: products.length,
    });
  const productsPerPage = products.slice(firstContentIndex, lastContentIndex);
  return (
    <section className="products-layout">
      <div className="products-container">
        {productsPerPage.length >= 1 &&
          productsPerPage.map(({ img, title, id }: { img: string; title: string; id: number }) => {
            return (
              <Card
                key={`cardMini${id}`}
                src={`${url}${img.split(';')[0]}`}
                alt={title}
                className={'card-mini'}
                onClick={() => {
                  const productName = getPathProduct(title);
                  navigator(`/product/${productName}/id/${id}`);
                }}
                captionActive={true}
              />
            );
          })}
      </div>
      <div className="pagination">
        <p className="text">
          {page}/{totalPages}
        </p>
        <div className="pagination-container">
          <button onClick={prevPage} className="page-button">
            &larr;
          </button>
          {[...Array(totalPages)].map((el, index) => (
            <button
              onClick={() => setPage(index + 1)}
              key={`page-number-${index}`}
              className={`page-button ${page === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
          <button onClick={nextPage} className="page-button">
            &rarr;
          </button>
        </div>
      </div>
    </section>
  );
}
