/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Title from '../../Title';

export default function About() {
  return (
    <section className="about-container">
      <Title className="about" label="О нас" />
      <div className="about-text-container">
        <p className="about-text">
          Компания ООО "Сенсор Автоматика" занимается производством и поставками промышленных
          контрольно-измерительных приборов. Наши специалисты помогут Вам подобрать оборудование
          непосредственно для решения Вашей задачи. Также мы предлагаем решения по измерению
          давления и температуры расплавов в экструдерах. Все сотрудники ООО "Сенсор Автоматика"
          много лет работают с промышленными приборами для измерения давления, уровня, температуры,
          учёта расхода жидкостей, смесей и газов и прочих областей промышленности и сфер
          применения. Миссия нашей компании заключается в предоставлении гарантий и максимальной
          выгоды нашим заказчикам и партнёрам при решении их задач. Мы готовы и можем достигать
          новых технических высот в области промышленного измерения вместе с теми, кто находится
          рядом.
        </p>
        {/* <button className="about-button">Подробнее</button> */}
      </div>
    </section>
  );
}
