import React from 'react';
import { Helmet } from 'react-helmet-async';
interface Tags {
  title: string;
  description?: string;
  canonical?: string | null;
  robots?: string;
}
interface Og {
  title: string;
  description?: string;
  image?: string;
  url?: string;
}
export default function SEO({ tags, og }: { tags: Tags; og: Og }) {
  return (
    <Helmet>
      <title>{tags.title}</title>
      <meta name="description" content={tags.description} />
      <meta
        name="robots"
        content={
          tags.robots
            ? tags.robots
            : 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'
        }
      />
      {tags.canonical && <link rel="canonical" href={tags.canonical} />}
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Сенсор Автоматика" />
      <meta property="og:title" content={og.title} />
      {og.description && <meta property="og:description" content={og.description} />}
      {og.url && <meta property="og:url" content={og.url} />}
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:image" content={og.image} />
      <meta property="og:image:width" content="968" />
      <meta property="og:image:height" content="504" />
    </Helmet>
  );
}
