import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import AsideMenu from '../../components/AsideMenu/AsideMenu';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { filterProducts } from '../../utils/utils';
import React from 'react';
import handleResize from '../../utils/handleResize';
import Pagination from '../../components/Pagination/Pagination';
import { Backdrop, CircularProgress } from '@mui/material';
import './Products.css';
import SEO from '../../components/Seo/Seo';

export default function Products() {
  const navigator = useNavigate();
  const products = useSelector((state: RootState) => state.products.products);
  const navigation = useSelector((state: RootState) => state.categories.navigation);
  const subcategories = useSelector((state: RootState) => state.categories.subcategories);
  const [currProducts, setCurrProducts] = useState(products);
  const [currentLocation, setCurrentLocation] = useState('');
  const { categoryNameId, subcategoryNameId } = useParams();
  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Продукция', to: '/produkcija' },
    { title: currentLocation, to: '' },
  ];
  const [mobile, setMobile] = useState(false);
  useEffect(() => handleResize(setMobile), []);
  useEffect(() => {
    if (navigation.length) {
      if (categoryNameId) {
        const categoryId = categoryNameId?.match(/\d+/g) ? categoryNameId?.match(/\d+/g)![0] : '9';
        const currentProducts = filterProducts(products, 'categoryid', categoryId);
        const currentLocation = navigation?.filter(({ id }) => id == categoryId);
        if (!currentLocation.length) {
          navigator('/page404');
        } else {
          setCurrProducts(currentProducts);
          setCurrentLocation(`/${currentLocation[0].title}`);
        }
      } else if (subcategoryNameId) {
        const subcategoryId = subcategoryNameId?.match(/\d+/g)
          ? subcategoryNameId?.match(/\d+/g)![0]
          : '9';
        const currentProducts = filterProducts(products, 'subcategoryid', subcategoryId);
        const currentLocation = subcategories?.flat(2).filter(({ id }) => id == subcategoryId);
        if (!currentLocation.length) {
          navigator('/page404');
        } else {
          setCurrProducts(currentProducts);
          setCurrentLocation(`/${currentLocation[0].title}`);
        }
      } else {
        setCurrProducts(products);
        setCurrentLocation('');
      }
    }
  }, [
    categoryNameId,
    products,
    subcategoryNameId,
    navigation,
    subcategories,
    currentLocation,
    navigator,
  ]);
  const tags = {
    title: 'Продукция компании Сенсор-Автоматика',
    description:
      'Компания Сенсор-Автоматика поставляет различные виды контрольно-измерительного оборудования: датчики давления, датчики температуры, датчики уровня и другие',
    canonical: 'https://sensor-automatic.ru/produkcija',
  };
  const og = {
    title: tags.title,
    description: tags.description,
    url: tags.canonical,
  };
  return (
    <Fragment>
      <SEO tags={tags} og={og} />
      <Header />
      <Breadcrumbs location={location} />
      {!navigation.length && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" size={60} />
        </Backdrop>
      )}
      <div className="products">
        {!mobile && <AsideMenu />}
        <Pagination products={currProducts} />
      </div>
      <Footer />
    </Fragment>
  );
}
