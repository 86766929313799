import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IProduct from '../../interfaces/IProduct';
import { getProductNav } from '../../utils/utils';
import getProductPath from '../../utils/getPathProduct';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import './ProductNav.css';

export default function ProductNav() {
  const products = useSelector((state: RootState) => state.products.products);
  const { id } = useParams();
  const navigator = useNavigate();
  const productId = id ? id : '1';
  const [[previousProduct, nextProduct], setNav] = useState<(string | IProduct)[]>(['', '']);
  useEffect(() => {
    setNav(getProductNav(products, productId));
  }, [productId, products]);
  return (
    <>
      <nav className="nav-products">
        <div
          className={`previous ${typeof previousProduct !== 'string' ? 'active' : null}`}
          onClick={() => {
            typeof previousProduct !== 'string'
              ? navigator(
                  `../product/${getProductPath(previousProduct?.title)}/id/${previousProduct?.id}`,
                  { replace: true }
                )
              : null;
          }}
        >
          <div className="nav-products-arrow previous"></div>
          <p>{typeof previousProduct !== 'string' ? previousProduct?.title : previousProduct}</p>
        </div>
        <div
          className={`next ${typeof nextProduct !== 'string' ? 'active' : null}`}
          onClick={() => {
            typeof nextProduct !== 'string'
              ? navigator(
                  `../product/${getProductPath(nextProduct?.title)}/id/${nextProduct?.id}`,
                  { replace: true }
                )
              : null;
          }}
        >
          <p>{typeof nextProduct !== 'string' ? nextProduct?.title : nextProduct}</p>
          <div className="nav-products-arrow next"></div>
        </div>
      </nav>
    </>
  );
}
