import Container from '../../components/Container/Container';
import industries from '../../assets/data/industries/industries';
import AsideMenu from '../../components/AsideMenu/AsideMenu';
import ItemContent from '../../components/ItemContent/ItemContent';
import React, { useEffect, useState } from 'react';
import handleResize from '../../utils/handleResize';
import SEO from '../../components/Seo/Seo';

export default function Industries() {
  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Отрасли', to: '/otrasli' },
  ];
  const tags = {
    title: 'Контрольно-измерительное оборудование для различных отраслей промышленности',
    description:
      'Датчики давления, датчики уровня, датчики температуры и другое измерительное оборудование от компании Сенсор-Автоматика для различных отраслей промышленности',
    canonical: 'https://sensor-automatic.ru/otrasli',
  };
  const og = {
    title: 'Контрольно-измерительное оборудование для различных отраслей промышленности',
  };
  const { links: industriesData } = industries;
  const [mobile, setMobile] = useState(false);
  useEffect(() => handleResize(setMobile), []);
  return (
    <>
      <SEO tags={tags} og={og} />
      <Container location={location}>
        <>{!mobile && <AsideMenu />}</>
        <ItemContent data={industriesData} section={'otrasli'} />
      </Container>
    </>
  );
}
