import { createSlice } from '@reduxjs/toolkit';
import INews from '../interfaces/INews';

interface INewsState {
  news: INews[];
}
const initialState: INewsState = {
  news: [{ images: '', alt: '', date: '', title: '', description: '', id: '' }],
};
export const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsState: (state, action) => {
      state.news = action.payload;
    },
  },
});
export const { setNewsState } = slice.actions;
export default slice.reducer;
