export default function ProductDot({
  dotNumber,
  goToSlide,
  currentActive,
}: {
  dotNumber: number;
  goToSlide: (number: number) => void;
  currentActive: number;
}) {
  return (
    <div
      key={`dot-number${dotNumber}`}
      onClick={() => {
        goToSlide(+dotNumber);
      }}
      className={`dot ${currentActive === dotNumber ? 'selected' : ''} product-controls `}
    />
  );
}
//
