import Container from '../../components/Container/Container';
import ItemContent from '../../components/ItemContent/ItemContent';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import AsideNews from '../../components/AsideMenu/AsideNews';
import React, { useEffect, useState } from 'react';
import handleResize from '../../utils/handleResize';
import SEO from '../../components/Seo/Seo';

export default function News() {
  const news = useSelector((state: RootState) => state.news.news);
  const [mobile, setMobile] = useState(false);
  useEffect(() => handleResize(setMobile), []);
  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Новости', to: '/news' },
  ];
  const tags = {
    title: 'Новости компании Сенсор-Автоматика',
    description:
      'Компания Сенсор-Автоматика поставляют своим клиентам различные виды датчиков давления, датчики давления расплава, датчики температуры расплава и другие виды измерительного оборудования',
    canonical: 'https://sensor-automatic.ru/news',
  };
  const og = {
    title: tags.title,
    description: tags.description,
    url: tags.canonical,
  };
  return (
    <>
      <SEO tags={tags} og={og} />
      <Container location={location}>
        <>
          {!mobile && <AsideNews data={news} className="news" sectionTitle="Последние новости" />}
        </>
        <ItemContent className="news" data={news} section={'news'} />
      </Container>
    </>
  );
}
