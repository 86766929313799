import React from 'react';
import getPath from '../../utils/getPath';
import ButtonMore from '../ButtonMore/ButtonMore';

export default function CardNews({
  src,
  alt,
  date,
  title,
  text,
  id,
  onClick,
}: {
  src: string;
  alt: string;
  date: string;
  title: string;
  text: string;
  id: string;
  onClick: () => void;
}) {
  return (
    <div className="news-container">
      <img src={src} alt={alt} className={`img-news`} onClick={onClick} />
      <div className="news-text">
        <div className="date-news">
          <div className="day-news">{date.split('.')[0]}</div>
          <div className="month-news">
            {date.split('.')[1]}
            <br />
            {date.split('.')[2]}
          </div>
        </div>
        <div className="news-text-container">
          <h4 className="news-title">{title}</h4>
          <p>{text}</p>
          <ButtonMore to={`${getPath('news', title)}-${id}`} />
        </div>
      </div>
    </div>
  );
}
