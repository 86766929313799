import { Link } from 'react-router-dom';
import './ButtonMore.css';

export default function ButtonMore({ to, className }: { to: string; className?: string }) {
  return (
    <Link className={`button-more ${className}`} to={to}>
      Подробнее
    </Link>
  );
}
