import { Fragment } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './Container.css';

export default function Container({
  location,
  children,
  className,
}: {
  location: { [key: string]: string }[];
  children: JSX.Element[] | JSX.Element;
  className?: string;
}) {
  return (
    <Fragment>
      <Header />
      <Breadcrumbs location={location} />
      <div className={`container${className ? className : ''}`}>{children}</div>
      <Footer />
    </Fragment>
  );
}
