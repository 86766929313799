import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import url from '../../../constants/host';
import Card from '../../Card/Card';
import Button from '../../Button';
import Title from '../../Title';
import getPathProduct from '../../../utils/getPathProduct';

export default function Top({ className }: { className: string }) {
  const popularProducts = useSelector((state: RootState) => state.products.products).filter(
    ({ special }) => special === 'popular'
  );
  const navigator = useNavigate();
  return (
    <section className={`${className}-section`}>
      <Title className={'top'} label={'Популярные товары'} />
      <Button
        className={`${className}-button`}
        onClick={() => navigator('../produkcija')}
        label={'Подробнее'}
      />
      <div className={`${className}-card-cover`}>
        {popularProducts.map(({ img, alt, title, id }, index) => (
          <Card
            key={`${className}${index}`}
            src={(url + img) as string}
            alt={alt as string}
            className={`${className}-card`}
            onClick={() => {
              const productName = getPathProduct(title);
              navigator(`/product/${productName}/id/${id}`);
            }}
            captionActive={true}
          />
        ))}
      </div>
    </section>
  );
}
