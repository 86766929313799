import energy from '../../industries/energy.jpg';
import engines from '../../industries/engines.jpg';
import food from '../../industries/food-ind.jpg';
import tests from '../../industries/tests.jpg';
import water from '../../industries/water.jpeg';
import hydraulics from '../../industries/hydraulics.jpg';
import hydrology from '../../industries/hydrology.jpg';
import hvac from '../../industries/hvac.jpg';
import medicine from '../../industries/medicine.jpg';
import agroindustry from '../../industries/agroindustry.jpeg';
import plastic from '../../industries/plastic.jpeg';

export default {
  title: 'Отрасли',
  to: '/otrasli',
  links: [
    {
      title: 'Энергетика',
      to: '/otrasli/energetika',
      images: energy,
      description: '',
    },
    {
      title: 'Двигателестроение',
      to: '/otrasli/dvigatelestroenie',
      images: engines,
      description: '',
    },
    {
      title: 'Гидравлика и пневматика',
      to: '/otrasli/gidravlika ',
      images: hydraulics,
      description: '',
    },
    {
      title: 'Испытательные стенды',
      to: '/otrasli/ispytatelnye-stendy',
      images: tests,
      description: '',
    },
    {
      title: 'Гидрология и гидрогеология',
      to: '/otrasli/gidrologija-i-gidrogeologija',
      images: hydrology,
      description: '',
    },
    {
      title: 'Водоподготовка',
      to: '/otrasli/vodopodgotovka',
      images: water,
      description: '',
    },
    {
      title: 'Пищепром и фармакология',
      to: '/otrasli/pishcheprom-i-farmakologiya',
      images: food,
      description: '',
    },
    {
      title: 'Агропром и сельхоз',
      to: '/otrasli/agroprom',
      images: agroindustry,
      description: '',
    },
    {
      title: 'ОВК',
      to: '/otrasli/ovk',
      images: hvac,
      description: '',
    },
    {
      title: 'Медицина',
      to: '/otrasli/medicina',
      images: medicine,
      description: '',
    },
    {
      title: 'Производство полимеров и пластмасс',
      to: '/otrasli/plastic',
      images: plastic,
      description: '',
    },
  ].map((val, index) => ({ ...val, id: `${index + 1}` })),
};
