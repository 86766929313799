import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CardNews from './CardNews';
import NewsArrows from './NewsSliderArrows';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export default function SliderNewsMobile() {
  const newsData = useSelector((state: RootState) => state.news.news);
  const [slide, setSlide] = useState(0);
  //const data: INews[] = [];
  const navigator = useNavigate();
  const changeSlide = useCallback(
    (direction = 1) => {
      let slideNumber = 0;
      if (slide + direction < 0) {
        slideNumber = newsData.length - 2;
      } else {
        slideNumber = (slide + direction) % (newsData.length - 1);
      }
      setSlide(slideNumber);
    },
    [newsData.length, slide]
  );
  return (
    <>
      <div className="slider-news-container animated fadeInUp">
        {newsData.map((news, index) => (
          <div
            key={`slider-container-${index}`}
            style={{ transform: `translateX(-${slide * 100}%)` }}
          >
            <CardNews
              key={`news-${index}`}
              src={news.images}
              alt={news.title}
              date={news.date}
              title={news.title}
              text={news.description}
              id={news.id.toString()}
              onClick={() => navigator(`../news/${news.id.toString()}`)}
            />
          </div>
        ))}
      </div>
      <NewsArrows className="news" onClick={changeSlide} />
    </>
  );
}
