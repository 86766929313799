import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Card from '../../components/Card/Card';
import Container from '../../components/Container/Container';
import url from '../../constants/host';
import { RootState } from '../../store/store';
import getPathProduct from '../../utils/getPathProduct';
import { Backdrop, CircularProgress } from '@mui/material';
import Form from '../../components/Form/Form';
import randomInteger from '../../utils/randomInteger';
import './Page404.css';
import SEO from '../../components/Seo/Seo';

export default () => {
  const navigator = useNavigate();
  const location = [{ title: `О компании`, to: '/' }];
  const productsAll = useSelector((state: RootState) => state.products.products);
  const random = randomInteger(4, productsAll.length - 1);
  const products = productsAll.slice(random - 4, random);
  const tags = {
    title: 'Страница не найдена - Сенсор-Автоматика',
    robots: '<meta name="robots" content="noindex, follow">',
  };
  const og = {
    title: 'Страница не найдена - Сенсор-Автоматика',
  };
  return (
    <>
      <SEO tags={tags} og={og} />
      <Container location={location} className=" page-404">
        <div className="page-404-info">Страница не найдена</div>
        <div className="page-404-card-container">
          <span>Возможно, Вас заинтересует:</span>
          {!products.length && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" size={60} />
            </Backdrop>
          )}
          <div className="products-container-404">
            {products.map(({ id, img, title }) => (
              <Card
                key={id}
                src={`${url}${img.split(';')[0]}`}
                alt={title}
                className={'card-mini'}
                onClick={() => {
                  const productName = getPathProduct(title);
                  navigator(`/product/${productName}/id/${id}`);
                }}
                captionActive={true}
              />
            ))}
          </div>
        </div>
        <Form title={true} className={'general'} button={'button'} />
      </Container>
    </>
  );
};
