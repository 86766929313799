import React from 'react';
import './Burger.css';

interface IBurger {
  handleClick: () => void;
  className?: boolean;
}

const Burger: React.FunctionComponent<IBurger> = ({ className, handleClick }) => {
  return (
    <div className={`${className ? 'active ' : ''}burger-block`} onClick={handleClick}>
      <div className="burger-line"></div>
      <div className="burger-line"></div>
      <div className="burger-line"></div>
    </div>
  );
};

export default Burger;
