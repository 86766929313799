import { NavLink } from 'react-router-dom';
import INews from '../../interfaces/INews';
import './AsideMenu.css';
import getPath from '../../utils/getPath';

export default function AsideNews({
  data,
  className,
  sectionTitle,
}: {
  data: INews[];
  className: string;
  sectionTitle: string;
}) {
  return (
    <aside className={`aside-${className}`}>
      <h4>{sectionTitle}</h4>
      <nav className={`aside-${className}-nav`}>
        <ul>
          {data.map(({ title, id, images, date }, index) => {
            return (
              <li key={`${className}-${index}`} className={`${className}-category-item`}>
                <br />
                <NavLink className="aside-news-link" to={`${getPath('news', title)}-${id}`}>
                  {<img src={images} />}
                </NavLink>
                <div>
                  <NavLink className="aside-news-title" to={`${getPath('news', title)}-${id}`}>
                    {title}
                  </NavLink>
                  <p>{date.split('.').join(' ')}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
}
