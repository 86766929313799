import React from 'react';
import Menu from '../Menu/Menu';
import vk from '../../assets/icons/vk.svg';
import Contacts from '../Contacts/Contacts';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-contacts-container animated fadeInUp">
        <div className="footer-contacts">
          <div className="footer-contacts-info">
            <Contacts email="order@sensor-automatic.ru" />
          </div>
          <div className="footer-time">
            <b>Время работы:</b> <br /> Пн-Чт с 8:00 до 17:00 <br /> Пт с 8:00 до 16:00 <br />
            <a
              href="https://vk.com/sensor.automatic"
              className="nav-link d-inline"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={vk} />
            </a>
          </div>
        </div>
        <div className="navigation-footer">
          <h4>Навигация:</h4>
          <Menu active={true} className={'footer-'} displayDropdown={false} />
        </div>
      </div>
    </footer>
  );
};
export default Footer;
