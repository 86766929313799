//import React, { useContext } from 'react';
import './Arrows.css';

export default function Arrows({
  className,
  changeSlide,
}: {
  className: string;
  changeSlide: (num: number) => void;
}) {
  return (
    <div className={`arrows-${className}`}>
      <div className={`arrow arrow-${className} arrow-prev`} onClick={() => changeSlide(-1)} />
      <div className={`arrow arrow-${className} arrow-next`} onClick={() => changeSlide(1)} />
    </div>
  );
}
