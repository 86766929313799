interface Img {
  title: string;
  to: string;
  images: string;
  description: string;
  id?: string;
}
export default (slides: Img[], slidesNumber: number) => {
  const slider: Img[][] = [];
  let sliderContainer: Img[] = [];
  slides.forEach((slide, index) => {
    sliderContainer.push(slide);
    if ((index + 1) % slidesNumber === 0) {
      slider.push(sliderContainer);
      sliderContainer = [];
    }
  });
  return slider;
};
