import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsideMenu from '../../components/AsideMenu/AsideMenu';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { RootState } from '../../store/store';
import Pagination from '../../components/Pagination/Pagination';
import search from '../../utils/search';
import IProduct from '../../interfaces/IProduct';
import './SearchResults.css';

export default function SearchResults() {
  const { searchQuery } = useParams<string>();
  const products = useSelector((state: RootState) => state.products.products);
  const [searchResults, setSeacrhResults] = useState<IProduct[]>([]);
  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Продукция', to: '/produkcija' },
  ];
  useEffect(() => {
    const res = search(products, searchQuery as string);
    setSeacrhResults(res);
  }, [products, searchQuery]);
  return (
    <Fragment>
      <Header />
      <Breadcrumbs location={location} />
      {!products.length && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" size={60} />
        </Backdrop>
      )}
      <div className="products">
        <AsideMenu />
        <section className="products-layout">
          <div className="search-container">
            {products.length > 0 && searchResults.length < 1 && (
              <h4>Извините, по запросу &quot;{searchQuery}&quot; ничего не найдено</h4>
            )}
            {searchResults.length >= 1 && (
              <h4 className="search-title">
                По запросу &quot;{searchQuery}&quot; найдены следующие товары:
              </h4>
            )}
          </div>
          <Pagination products={searchResults} />
        </section>
      </div>
      <Footer />
    </Fragment>
  );
}
