import { createSlice } from '@reduxjs/toolkit';
import IProduct from '../interfaces/IProduct';

interface IProductsState {
  products: IProduct[];
  searchResults: IProduct[] | [];
  searchQuery: string;
}
const initialState: IProductsState = {
  products: [],
  searchResults: [],
  searchQuery: '',
};
export const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsState: (state, action) => {
      state.products = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    // setPrevious: (state, action) => {
    //   const temp = [...state.previousProducts];
    //   temp.push(action.payload);
    //   const unique: { title: string; img: string; id: number }[] = [];
    //   temp.forEach((el) => {
    //     const isNotUnique = unique.reduce((acc, uniqueEl) => el.id === uniqueEl.id, false);
    //     if (!isNotUnique) unique.push(el);
    //   });
    //   state.previousProducts = unique;
    // },
  },
});
export const { setProductsState, setSearchResults, setSearchQuery } = slice.actions;
export default slice.reducer;
