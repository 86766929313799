import React, { useEffect, useState } from 'react';

export default function Slide({
  cover,
  setLoaded,
  style,
}: {
  title?: string;
  cover: string;
  setLoaded: (val: boolean) => void;
  style: string;
}) {
  const [imgSrc, setImgSrc] = useState(cover);
  useEffect(() => {
    const img = new Image();
    img.src = cover;
    img.onload = () => {
      setImgSrc(cover);
      setLoaded(true);
    };
  }, [cover, setLoaded]);
  return <div className="slide" style={{ backgroundImage: `url(${imgSrc})`, display: style }} />;
}
