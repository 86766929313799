import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Menu from '../Menu/Menu';
import SearchBar from '../SearchBar/SearchBar';
import logo from '../../assets/logo.png';
import Burger from '../Burger/Burger';
import './Header.css';

const Header = () => {
  const navigator = useNavigate();
  const [menuActive, setMenuActive] = useState(false);
  return (
    <header className="header">
      <div className="logo">
        <img className="logo-img" src={logo} alt={'logo'} onClick={() => navigator('..')} />
        <p className="logo-text">контрольно-измерительное оборудование</p>
      </div>
      <div className="nav-container">
        <SearchBar />
        <Burger className={menuActive} handleClick={() => setMenuActive(!menuActive)} />
        <Menu active={menuActive} className={''} displayDropdown={true} />
      </div>
    </header>
  );
};
export default Header;
