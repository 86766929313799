import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AsideNews from '../../components/AsideMenu/AsideNews';
import Container from '../../components/Container/Container';
import url from '../../constants/host';
import { getNewsById } from '../../requests/news';
import { RootState } from '../../store/store';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import getDateString from '../../utils/getDateString';
import ImagePreload from '../../components/Image/ImagePreload';
import './CurrentNews.css';
import React from 'react';
import SEO from '../../components/Seo/Seo';
import { getLengthUntilSpace } from '../../utils/utils';

export default () => {
  const navigator = useNavigate();
  const news = useSelector((state: RootState) => state.news.news);
  const { newsName } = useParams<string>() || '';
  const newsArr = newsName?.split('-') || [];
  const id = newsArr[newsArr.length - 1];
  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Новости', to: '/news' },
  ];
  const [newsCurr, setNews] = useState({
    id: id,
    title: '',
    description: '',
    images: '',
    date: '',
  });
  const [dateStr, setDate] = useState('');
  const { images, title, description, date } = newsCurr;
  const [length, setLength] = useState(190);

  useEffect(() => {
    const getNews = async () => {
      const news = await getNewsById(id);
      if (!news || !news.title) {
        navigator('/page404');
      } else {
        setNews(news);
        setDate(getDateString(date).split('.').join(' '));
        const length = getLengthUntilSpace(news.description, 190) || 190;
        setLength(length);
      }
    };
    getNews();
  }, [date, id, navigator]);
  const tags = {
    title: title,
    description: description.slice(0, length),
    canonical: window.location.href,
  };
  const og = {
    title: tags.title,
    description: tags.description,
    image: images,
    url: tags.canonical,
  };
  return (
    <>
      <SEO tags={tags} og={og} />
      <Container location={location}>
        <>
          {!newsCurr.images && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" size={60} />
            </Backdrop>
          )}
        </>
        <AsideNews data={news} className="news" sectionTitle="Последние новости" />
        <div className="current-news-container">
          <h3>{title}</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {<span>{dateStr}</span>}
            <div className="icon-container">
              <CalendarMonthIcon />
            </div>
          </div>
          <ImagePreload src={`${url}${images}`} alt={title} />
          <p>{description}</p>
        </div>
      </Container>
    </>
  );
};
