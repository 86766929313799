import ProductDot from './ProductDot';

export default function Dots({
  goToSlide,
  slidesAmount,
  currentActive,
  className,
}: {
  goToSlide: (number: number) => void;
  slidesAmount: number;
  currentActive: number;
  className: string;
}) {
  let i = 0;
  const dots = [];
  do {
    dots.push(
      <ProductDot
        goToSlide={goToSlide}
        key={`product-dot${i}`}
        currentActive={currentActive}
        dotNumber={i}
      />
    );
    i++;
  } while (i < slidesAmount);
  return <div className={className}>{dots}</div>;
}
