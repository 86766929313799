import INews from '../interfaces/INews';
import host from '../constants/host';

const postNews = (product: { title: string; description: string }) => {
  fetch(`${host}api/news`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(product),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
const getAllNews = async () => {
  return await fetch(`${host}api/news`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
const getNewsById = async (id = '1') => {
  return await fetch(`${host}api/news/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
const updateNews = async (news: INews, id: string) => {
  return await fetch(`${host}api/news/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(news),
  }).catch((err) => console.error(err));
};
export { postNews, getAllNews, updateNews, getNewsById };
