export default [
  {
    id: '9',
    title: 'Давление',
    subcategories: [
      { id: '7', title: 'Датчики давления общепромышленные', categoryid: '9' },
      { id: '8', title: 'Датчики давления расплава', categoryid: '9' },
    ],
  },
  {
    id: '10',
    title: 'Уровень',
    subcategories: [
      { id: '9', title: 'Датчики уровня гидростатические', categoryid: '10' },

      { id: '10', title: 'Датчики уровня радарные и УЗ', categoryid: '10' },
    ],
  },
  {
    id: '11',
    title: 'Температура',
    subcategories: [
      { id: '12', title: 'Датчики температуры расплава', categoryid: '11' },
      { id: '11', title: 'Датчики температуры общепромышленные', categoryid: '11' },
    ],
  },
  // {
  //   id: '12',
  //   title: 'ОВК (HVAC)',
  //   subcategories: [
  //     { id: '13', title: 'Датчики температуры', categoryid: '12' },
  //     { id: 14, title: 'Датчики СО2', categoryid: '12' },
  //     { id: 15, title: 'Датчики СО', categoryid: '12' },
  //     { id: 19, title: 'Газоанализаторы', categoryid: '12' },
  //   ],
  // },
  {
    id: '13',
    title: 'Расход',
    subcategories: [
      { id: '20', title: 'Расходомеры жидкости', categoryid: '13' },
      { id: '21', title: 'Расходомеры газов', categoryid: '13' },
    ],
  },
  {
    id: '14',
    title: 'Аксессуары',
    subcategories: [
      { id: '22', title: 'Разъёмы электрические', categoryid: '14' },
      { id: '23', title: 'Переходники резьбовые', categoryid: '14' },
      { id: '24', title: 'Бобышки вварные', categoryid: '14' },
    ],
  },
];
