import { configureStore } from '@reduxjs/toolkit';
import categories from './categoriesSlice';
import products from './productSlice';
import news from './newsSlice';
const store = configureStore({
  reducer: {
    categories,
    products,
    news,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
