import React, { Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown from './Dropdown';
import { getMenu } from './getMenu';
import categoriesData from '../../assets/data/categoriesData';

import './Menu.css';
import IMenu from '../../interfaces/IMenu';

const Menu = ({
  active,
  className,
  displayDropdown,
}: {
  active: boolean;
  className: string;
  displayDropdown: boolean;
}) => {
  const [dropdownActive, setDropdownActive] = useState<{ [key: string]: boolean }>({});
  const [MENU, setMenu] = useState<IMenu[]>([]);
  useEffect(() => {
    const menu = getMenu(categoriesData) as IMenu[];
    setMenu(menu);
  }, []);
  return (
    <Fragment>
      <nav className={`${className}menu`}>
        <ul className={active ? 'menu-list active' : 'menu-list'}>
          {MENU.map(({ title, to, links }, index) => (
            <li className="list-item" key={index}>
              <NavLink
                className="nav-link"
                to={to}
                onMouseEnter={() => {
                  if (links) setDropdownActive({ [title]: true });
                }}
                onMouseOut={() => {
                  setDropdownActive({ [title]: false });
                }}
              >
                {title}
              </NavLink>
              {displayDropdown && dropdownActive[title] && (
                <>
                  <Dropdown
                    links={links}
                    className={'dropdown'}
                    onMouseEnter={() => {
                      if (links)
                        setDropdownActive((prevState) => ({ ...prevState, [title]: true }));
                    }}
                    onMouseLeave={() => {
                      setDropdownActive((prevState) => ({ ...prevState, [title]: false }));
                    }}
                  />
                </>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </Fragment>
  );
};

export default Menu;
