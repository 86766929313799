import ICategory from '../interfaces/ICategory';
import ISubcategory from '../interfaces/ISubcategory';
import getPath from './getPath';

const getNavigation = (categories: ICategory[], type = 'category') =>
  categories.map((item) => ({
    ...item,
    to: getPath('produkcija', item.title!, type),
    title: item.title!,
  }));
const getNavigationSubcategories = (categories: ISubcategory[], type = 'subcategory') =>
  categories.map((item) => ({
    ...item,
    to: getPath('produkcija', item.title, type),
    title: item.title,
  }));
export { getNavigation, getNavigationSubcategories };
