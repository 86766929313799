import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import ImagePreload from '../Image/ImagePreload';

export default function Card({
  src,
  alt,
  className,
  path,
  onClick,
  captionActive,
}: {
  src: string;
  alt: string;
  className: string;
  path?: string;
  onClick?: () => void;
  captionActive: boolean;
}) {
  return (
    <Fragment>
      {path && <NavLink to={path} />}
      <div className={className} onClick={onClick}>
        <div className={`img-${className}-container`}>
          <ImagePreload src={`${src.split(';')[0]}`} alt={alt} className={`img-${className}`} />
        </div>
        {captionActive && <span className={`span-${className}`}>{alt}</span>}
      </div>
    </Fragment>
  );
}
