import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../Card/Card';
import Button from '../../Button';
import Title from '../../Title';
import getPathProduct from '../../../utils/getPathProduct';
import getSlider from '../../../utils/getSlider';
import Arrows from '../Slider/Arrows/Arrows';
import industries from '../../../assets/data/industries/industries';
import './Industries.css';

export default function Industries({ className }: { className: string }) {
  const navigator = useNavigate();
  const [slide, setSlide] = useState(0);
  const slider = getSlider(industries.links, 4);
  const changeSlide = useCallback(
    (direction = 1) => {
      let slideNumber = 0;
      if (slide + direction < 0) {
        slideNumber = slider.length - 1;
      } else {
        slideNumber = (slide + direction) % slider.length;
      }
      setSlide(slideNumber);
    },
    [slider.length, slide]
  );
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     changeSlide(1);
  //   }, 4000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [changeSlide, slider.length, slide]);
  return (
    <section className={`${className}-section`}>
      <Title className="industries" label="Отрасли" />
      <Button
        className={`${className}-button`}
        onClick={() => navigator('../otrasli')}
        label={'Подробнее'}
      />
      <div className="industries-slider animated fadeInUp">
        {slider.map((inner, index) => (
          <div
            className="industry-slider-container"
            key={`slider${index}`}
            style={{ transform: `translateX(-${slide * 100}%)` }}
          >
            {inner.map(({ images, title, id }, indexInner) => (
              <Card
                key={`${className}${index + indexInner}`}
                src={images}
                alt={title}
                className={`industry-card`}
                onClick={() => {
                  const industryName = getPathProduct(title).toLowerCase();
                  navigator(`../otrasli/${industryName}-${id}`);
                }}
                captionActive={true}
              />
            ))}
          </div>
        ))}
      </div>
      <Arrows className="industries" changeSlide={changeSlide} />
    </section>
  );
}
