import months from '../constants/months';

export default (dateParam: string) => {
  const date = new Date(dateParam.replace(/\./g, '-'));
  if (date.toDateString() !== 'Invalid Date') {
    return `${date.getDate()}.${months[date.getMonth()]}.${date.getFullYear()}`;
  } else {
    return '';
  }
};
