import React, { useContext } from 'react';
import { SliderContext } from '../Slider';

import './Dots.css';

export default function Dot({ number }: { number: number }) {
  const { goToSlide, slideNumber } = useContext(SliderContext);

  return (
    <div
      className={`dot ${slideNumber === number ? 'selected' : ''}`}
      onClick={() => goToSlide(number)}
    />
  );
}
