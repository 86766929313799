import host from '../constants/host';

interface IProduct {
  title: string;
  description: string;
  categoryid: number;
  subcategoryid: number;
  docs?: string;
  img?: string;
  features?: string;
  use?: string;
}

const postProduct = (product: { title: string; description: string }) => {
  fetch(`${host}api/products`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(product),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
const getAllProducts = async () => {
  return await fetch(`${host}api/products`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
const deleteProduct = (id: string) => {
  fetch(`${host}api/products/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err: Error) => console.error(err));
};
const getProductById = async (id = '1') => {
  return await fetch(`${host}api/products/${id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};
const updateProduct = async (id: string, product: IProduct) => {
  return await fetch(`${host}api/products/${id}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(product),
  })
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => console.error(err));
};
export { postProduct, getAllProducts, deleteProduct, getProductById, updateProduct };
