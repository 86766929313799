import categoriesData from '../../assets/data/categoriesData';
import industries from '../../assets/data/industries/industries';
import ICategory from '../../interfaces/ICategory';
export const getMenu = (categories: ICategory[]) => [
  {
    title: 'О компании',
    to: '/',
  },
  {
    title: 'Продукция',
    to: '/produkcija',
    links: categories,
  },
  industries,
  {
    title: 'Новости',
    to: '/news',
  },
  {
    title: 'Контакты',
    to: '/kontakty',
  },
];
const categories: { [key: string]: boolean } = categoriesData.reduce(
  (acc, { title }) => ({ ...acc, [title]: false }),
  {}
);
export const dropdown = {
  Продукция: false,
  Отрасли: false,
  ...categories,
};
