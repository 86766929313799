import React from 'react';

import '../main/Slider/Arrows/Arrows.css';

export default function NewsArrows({
  className,
  onClick,
}: {
  className: string;
  onClick: (direction: number) => void;
}) {
  return (
    <div className={`arrows-${className}`}>
      <div className={`arrow-${className} arrow-prev`} onClick={() => onClick(-1)} />
      <div className={`arrow-${className} arrow-next`} onClick={() => onClick(1)} />
    </div>
  );
}
