import React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AsideMenu from '../../components/AsideMenu/AsideMenu';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ProductCard from '../../components/ProductCard/ProductCard';
import { getProductById } from '../../requests/products';
import { Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import handleResize from '../../utils/handleResize';
import './ProductPage.css';
import SEO from '../../components/Seo/Seo';
import { getLengthUntilSpace } from '../../utils/utils';

export default function ProductPage() {
  const navigator = useNavigate();
  const { productName, id } = useParams();
  const [product, setProduct] = useState({ title: '', img: '', description: '' });
  const [mobile, setMobile] = useState(false);
  const [length, setLength] = useState(190);
  useEffect(() => handleResize(setMobile), []);
  useEffect(() => {
    const getProduct = async () => {
      const product = await getProductById(id);
      if (!product || !product.title) {
        navigator('/page404');
      } else {
        setProduct(product);
        const length = getLengthUntilSpace(product.description, 190) || 190;
        setLength(length);
        localStorage.setItem('recent', id || '');
      }
    };
    getProduct();
  }, [id, navigator]);
  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Продукция', to: '/produkcija' },
    { title: `/ ${product?.title}`, to: productName || '' },
  ];
  const tags = {
    title: product.title,
    description: product.description.slice(0, length),
    canonical: window.location.href,
  };
  const og = {
    title: tags.title,
    description: tags.description,
    image: product.img,
    url: tags.canonical,
  };
  return (
    <Fragment>
      <SEO tags={tags} og={og} />
      <Header />
      <Breadcrumbs location={location} />
      <section className="product-container">
        {!product.title && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" size={60} />
          </Backdrop>
        )}
        {!mobile && <AsideMenu />}
        {product.img && <ProductCard product={product} />}
      </section>
      <Footer />
    </Fragment>
  );
}
