import React, { Fragment, useState } from 'react';
import ICategory from '../../interfaces/ICategory';
import ItemLink from '../ItemLink/ItemLink';
import getPath from '../../utils/getPath';
import { useResize } from '../../hooks/useResize';
const Dropdown = ({
  links,
  className,
  style,
  onMouseEnter,
  onMouseLeave,
}: {
  links: undefined | ICategory[];
  className: string;
  style?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  const [dropdownActive, setDropdownActive] = useState<{ [key: string]: boolean }>({});
  const { width } = useResize();
  if (!links) return <></>;
  return (
    <Fragment>
      <ul
        className={`${className}`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={style ? style : undefined}
      >
        {links?.map(({ title, id, subcategories, to }, index) => {
          const sectionInner = className === 'subcategories-dropdown' ? 'subcategory' : 'category';
          const toAlt = !to ? `../produkcija${getPath(sectionInner, title!)}-${id}` : `${to}-${id}`;
          return (
            <Fragment key={`empty${index}`}>
              <ItemLink
                key={`dropdownLink${index}`}
                title={title!}
                to={toAlt}
                subcategories={subcategories}
                onMouseEnterInner={() => {
                  setDropdownActive({ [title!]: true });
                }}
                onMouseLeaveInner={() => {
                  setDropdownActive({ [title!]: false });
                }}
              />
              {width > 770 && dropdownActive[title!] && subcategories && (
                <Dropdown
                  key={`subcategories-dropdown-${index}`}
                  style={{ position: 'absolute', top: `${32 * index}px`, left: '90%' }}
                  links={subcategories}
                  className={'subcategories-dropdown'}
                  onMouseEnter={() => setDropdownActive({ [title!]: true })}
                  onMouseLeave={() => setDropdownActive({ [title!]: false })}
                />
              )}
            </Fragment>
          );
        })}
      </ul>
    </Fragment>
  );
};

export default Dropdown;
