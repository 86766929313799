import { createSlice } from '@reduxjs/toolkit';
import ICategory from '../interfaces/ICategory';
import ISubcategory from '../interfaces/ISubcategory';

interface ICategoriesState {
  currentCategories: { id: number; name: string; subcategories: string[] }[];
  navigation: ICategory[];
  subcategories: ISubcategory[];
}
const initialState: ICategoriesState = {
  currentCategories: [],
  navigation: [],
  subcategories: [],
};
export const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.currentCategories = action.payload;
    },
    setNavigation: (state, action) => {
      state.navigation = action.payload;
    },
    setSubcategories: (state, action) => {
      state.subcategories.push(action.payload);
    },
  },
});
export const { setCategories, setNavigation, setSubcategories } = slice.actions;
export default slice.reducer;
