import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

import Main from '../src/routes/Main/Main';
import Products from './routes/ProductsRoute/Products';
import ProductPage from './routes/Product/ProductPage';
import Industries from './routes/Industries/Industries';
import IndustryPage from './routes/Industries/IndustryPage';
import Page404 from './routes/Page404/Page404';

import { useDispatch } from 'react-redux';
import { getAllProducts } from './requests/products';
import { setProductsState } from './store/productSlice';
import { setCategories, setNavigation, setSubcategories } from './store/categoriesSlice';
import { getNavigation, getNavigationSubcategories } from './utils/getNavigation';
import { ToastContainer } from 'react-toastify';
import categoriesData from './assets/data/categoriesData';
import url from './constants/host';
import INews from './interfaces/INews';
import { getAllNews } from './requests/news';
import { setNewsState } from './store/newsSlice';
import NewsPage from './routes/News/NewsPage';
import CurrentNewsPage from './routes/News/CurrentNewsPage';
import compareFunction from './utils/compareFunction';
import getDateString from './utils/getDateString';
import Contacts from './routes/Contacts/Contacts';
import SearchResults from './routes/SearchResults/SearchResults';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const getNews = async () => {
      const newsReq: INews[] = await getAllNews();
      newsReq.map((news) => (news.images = `${url}${news.images}`));
      const sortedNews: INews[] = newsReq.sort(compareFunction);
      const res = sortedNews.map((news) => {
        const dateStr = getDateString(news.date);
        return { ...news, date: dateStr };
      });
      dispatch(setNewsState(res));
    };
    getNews();
  }, [dispatch]);

  useEffect(() => {
    const getProducts = async () => {
      const products = await getAllProducts();
      dispatch(setProductsState(products));
    };
    getProducts();
  }, [dispatch]);

  useEffect(() => {
    const navigation = getNavigation(categoriesData);
    categoriesData.forEach((item) => {
      const sub = getNavigationSubcategories(item.subcategories);
      dispatch(setSubcategories(sub));
    });
    dispatch(setCategories(categoriesData));
    dispatch(setNavigation(navigation));
  }, [dispatch]);
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/page404" />} />
          <Route path="/" element={<Main />} />
          <Route path="/produkcija" element={<Products />} />
          <Route path="/produkcija/:pageNumber" element={<Products />} />
          <Route path={'produkcija/category/:categoryNameId'} element={<Products />} />
          <Route path={'produkcija/category/:categoryNameId/:pageNumber'} element={<Products />} />
          <Route path={'produkcija/subcategory/:subcategoryNameId'} element={<Products />} />
          <Route
            path={'produkcija/subcategory/:subcategoryNameId/:pageNumber'}
            element={<Products />}
          />
          <Route path={'product/:productName/id/:id'} element={<ProductPage />} />
          <Route path={'otrasli'} element={<Industries />} />
          <Route path={'otrasli/:industryName'} element={<IndustryPage />} />
          <Route path={'news'} element={<NewsPage />} />
          <Route path={'news/:newsName'} element={<CurrentNewsPage />} />
          <Route path={'kontakty'} element={<Contacts />} />
          <Route path={'page404'} element={<Page404 />} />
          <Route path={'search/:searchQuery'} element={<SearchResults />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
