import React, { useEffect, useState } from 'react';
import Dots from './ProductsDots';
import Form from '../Form/Form';
import ProductNav from '../ProductNav/ProductNav';
import url from '../../constants/host';
import ImagePreload from '../Image/ImagePreload';
import vk from '../../assets/icons/vk.svg';
import './ProductCard.css';
export default function ProductCard({ product }: { product: { [key: string]: string } }) {
  const [number, setNumber] = useState(0);
  const [src, setSrc] = useState<string[]>(['']);
  const [features, setFeatures] = useState<string[]>([]);
  const [use, setUse] = useState<string[]>([]);
  const formRef = React.createRef<HTMLElement>();
  const goToSlide = (number: number) => {
    setNumber(number);
  };
  useEffect(() => {
    const src: string[] = product.img.split(';').filter((el) => el);
    const features = product.features.split(/\,/);
    const use = product.use.split(';');
    setNumber(0);
    setSrc(src);
    setFeatures(features);
    setUse(use);
  }, [product]);
  return (
    <div className="product-card-wrapper">
      <div className="product-card-container">
        <div className="product-card-img-container">
          <div className="product-card">
            <ImagePreload
              className="product-card-img"
              src={`${url}${src[number]}`}
              alt={product.title}
            />
          </div>
          {src.length > 1 && (
            <Dots
              className={'product-controls-container'}
              goToSlide={goToSlide}
              slidesAmount={src.length}
              currentActive={number}
            />
          )}
        </div>
        <div className="product-additional">
          <h2>{product.title}</h2>
          <div className="product-details">
            <p>{product.description}</p>
          </div>
          <div className="vk-link">
            <a
              href={`https://vk.com/share.php?url=${window.location.href}`}
              target="_blank"
              rel="noreferrer"
            >
              <img src={vk} />
            </a>
          </div>
          <button
            className="link-to-form"
            onClick={() => {
              formRef?.current?.scrollIntoView();
            }}
          >
            Отправить запрос
          </button>
        </div>
      </div>
      <div className="product-features">
        <h4>Особенности</h4>
        <hr />
        <ul className="product-features-list">
          {features.map((feature, i) => (
            <li key={i}>{feature}</li>
          ))}
        </ul>
        <h4>Применение</h4>
        <hr />
        <ul className="product-features-list">
          {use.map((use, i) => (
            <li key={`use-${i}`}>{use}</li>
          ))}
        </ul>
        <h4>Загрузить</h4>
        <hr />
        <ul className="product-features-list">
          <a
            href={product.docs}
            target="_blank"
            style={product.docs ? { pointerEvents: 'all' } : { pointerEvents: 'none' }}
            rel="noreferrer"
          >
            {product.docs ? `Спецификация` : `Спецификация отсутвует`}
          </a>
        </ul>
      </div>
      <ProductNav />
      <Form ref={formRef} title={false} className={'product'} button={'button'} />
    </div>
  );
}
