import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import INews from '../../interfaces/INews';
import CardNews from './CardNews';
import NewsArrows from './NewsSliderArrows';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

export default function SliderNews() {
  const news = useSelector((state: RootState) => state.news.news);
  const [slide, setSlide] = useState(0);
  const data: INews[][] = [];
  let inner: INews[] = [];
  for (let i = 0; i < news.length; i++) {
    if (i % 2 == 0 && i !== 0) {
      data.push(inner);
      inner = [];
    }
    inner.push(news[i]);
    if (i === news.length - 1) data.push(inner);
  }
  const navigator = useNavigate();

  const changeSlide = useCallback(
    (direction = 1) => {
      let slideNumber = 0;
      if (slide + direction < 0) {
        slideNumber = news.length - 2;
      } else {
        slideNumber = (slide + direction) % (news.length - 1);
      }
      setSlide(slideNumber);
    },
    [news.length, slide]
  );

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     changeSlide(1);
  //   }, 4000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [changeSlide, news.length, slide]);
  return (
    <>
      <div className="slider-news-container animated fadeInUp">
        {data.map((inner, i) => (
          <div
            key={`inner-slider${i}`}
            className="inner-slider-news-container"
            style={{ transform: `translateX(-${slide * 50}%)` }}
          >
            {inner.map((news, index) => (
              <CardNews
                key={`news-${index}`}
                src={news.images}
                alt={news.title}
                date={news.date}
                title={news.title}
                text={news.description}
                id={news.id.toString()}
                onClick={() => navigator(`../news/${news.id.toString()}`)}
              />
            ))}
          </div>
        ))}
      </div>
      <NewsArrows className="news" onClick={changeSlide} />
    </>
  );
}
