import React from 'react';

import './Spinner.css';

export default ({
  containerStyle,
  spinnerStyle,
}: {
  containerStyle?: { [key: string]: string };
  spinnerStyle?: { [key: string]: string };
}) => {
  return (
    <div className="spinner-container" style={containerStyle}>
      <div className="loading-spinner" style={spinnerStyle}></div>
    </div>
  );
};
