import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { RootState } from '../../store/store';
import getPath from '../../utils/getPath';
import Card from '../Card/Card';
import url from '../../constants/host';
import getPathProduct from '../../utils/getPathProduct';
import './AsideMenu.css';

export default function AsideMenu() {
  const navigator = useNavigate();
  const navigation = useSelector((state: RootState) => state.categories.navigation);
  const products = useSelector((state: RootState) => state.products.products);
  const previous = localStorage.getItem('recent') || '';
  const previousProduct = products.filter((product) => product.id.toString() == previous)[0];
  const [captionActive, setCaptionActive] = useState(false);
  return (
    <aside className="aside-menu">
      <nav className="aside-menu-nav">
        <ul>
          {navigation.map(({ title, to, subcategories, id }, index) => {
            return (
              <li key={`category-${index}`} className="category-item">
                <br />
                <NavLink to={to ? `${to}-${id}` : ''}>{title}</NavLink>
                <ul className="subcategory">
                  {subcategories!.map(({ title, id }, index) => {
                    const to = getPath('produkcija', title, 'subcategory');
                    return (
                      <li key={`sub-${index}`} className="subcategory-item">
                        <NavLink to={`${to}-${id}`}>{title}</NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>
      {previousProduct && (
        <div
          className="previous-product-container"
          onMouseOver={() => setCaptionActive(true)}
          onMouseLeave={() => setCaptionActive(false)}
        >
          <h4>Недавно просмотренные</h4>
          <Card
            className={'previous-product'}
            src={`${url}${previousProduct.img}`}
            alt={previousProduct.title}
            captionActive={captionActive}
            onClick={() => {
              const productName = getPathProduct(previousProduct.title);
              navigator(`/product/${productName}/id/${previous}`);
            }}
          />
        </div>
      )}
    </aside>
  );
}
