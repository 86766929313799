import React from 'react';
import Header from '../../components/Header/Header';
import Slider from '../../components/main/Slider/Slider';
//import Hot from '../../components/main/Hot/Hot';
import Top from '../../components/main/Top/Top';
import Industries from '../../components/main/Industries/Industries';
import About from '../../components/main/About/About';
import News from '../../components/News/News';
import Footer from '../../components/Footer/Footer';
import './Main.css';
import SEO from '../../components/Seo/Seo';

const Main = () => {
  const tags = {
    title: 'Поставки промышленного контрольно-измерительного оборудования - Сенсор Автоматика',
    description:
      'Сенсор Автоматика - поставщик промышленного контрольно-измерительного оборудования на территории России и стран ЕЭП. Преобразователи давления и гидростатические датчики уровня воды как стандартных исполнений, так и выполненные по техническому заданию заказчика.',
    canonical: 'https://sensor-automatic.ru/',
  };
  const og = {
    title: tags.title,
    description: tags.description,
    url: tags.canonical,
  };
  return (
    <>
      <SEO tags={tags} og={og} />
      <Header />
      <Slider />
      <About />
      <Top className={'layout'} />
      <Industries className={'layout'} />
      <News />
      <Footer />
    </>
  );
};
export default Main;
