import url from '../constants/host';
const postMail = ({
  title,
  text,
  link,
  product,
}: {
  title: string;
  text: string;
  link: string;
  product: string;
}) => {
  return fetch(`${url}api/mailer`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ title, text, link, product }),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err));
};

export default postMail;
