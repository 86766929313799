import { useEffect, useState } from 'react';
import Spinner from '../Spinner/Spinner';
import nofoto from '../../assets/nofoto.png';

export default function ImagePreload({
  src,
  alt,
  className,
}: {
  src: string;
  alt: string;
  className?: string;
}) {
  const [imgSrc, setImgSrc] = useState(src);
  const [imgIsLoaded, setLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onerror = () => {
      setImgSrc(nofoto);
      setLoaded(true);
    };
    img.onload = () => {
      setImgSrc(src);
      setLoaded(true);
    };
  });
  return (
    <>
      <img
        src={imgSrc}
        alt={alt}
        className={className}
        style={imgIsLoaded ? { display: 'block' } : { display: 'none' }}
      />
      <>{!imgIsLoaded && <Spinner />}</>
    </>
  );
}
