import CyrillicToTranslit from 'cyrillic-to-translit-js';
const cyrillicToTranslit = CyrillicToTranslit();

export default (title: string) =>
  cyrillicToTranslit
    .transform(title)
    .split(/\W{1}/giu)
    .filter((el) => el)
    .join('-')
    .toLocaleLowerCase();
