import React from 'react';
import Contacts from '../../components/Contacts/Contacts';
import Container from '../../components/Container/Container';
import Form from '../../components/Form/Form';
import './Contacts.css';
import SEO from '../../components/Seo/Seo';

export default () => {
  const location = [
    { title: `О компании /`, to: '/' },
    { title: 'Контакты', to: '/kontakty' },
  ];
  const tags = {
    title: 'Контакты компании Сенсор-Автоматика',
    description: 'Адрес, телефон, время работы компании Сенсор-Автоматика',
    canonical: 'https://sensor-automatic.ru/kontakty',
  };
  const og = {
    title: 'Контакты',
    description: 'Адрес, телефон, время работы компании Сенсор-Автоматика',
    url: 'https://sensor-automatic.ru/kontakty',
  };
  return (
    <>
      <SEO tags={tags} og={og} />
      <Container location={location} className=" container-contacts">
        <div className="contacts-form">
          <div className="contacts-info">
            <Contacts email="info@sensor-automatic.ru" />
            <b>Время работы:</b> <br /> Пн-Чт с 8:00 до 17:00 <br /> Пт с 8:00 до 16:00 <br />
          </div>
          <Form title={true} className={'contacts'} button={'button'} />
        </div>
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae9d1c175489b4a566cfdf002034663a6f1b425e0b2fc678cf888f609c921efa4&amp;source=constructor"
          width="100%"
          height="416"
          allowFullScreen={true}
          className="frame"
        ></iframe>
      </Container>
    </>
  );
};
