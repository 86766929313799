import React, { useEffect, useState, createContext, useCallback } from 'react';
import Arrows from './Arrows/Arrows';
import Dots from './Dots/Dots';
import photo1 from '../../../assets/slider/photo.jpg';
import photo2 from '../../../assets/slider/photo2.jpg';
import photo3 from '../../../assets/slider/photo3.jpg';
import photo5 from '../../../assets/slider/photo5.jpg';
import SliderList from './SliderList/SliderList';
import './Slider.css';

interface ISlider {
  goToSlide: (number: number) => void;
  changeSlide: (direction: number) => void;
  slidesCount: number;
  slideNumber: number;
  items: { title: string; url: string }[];
}
export const SliderContext = createContext<ISlider>({
  goToSlide: (number) => {
    number;
  },
  changeSlide: (direction) => {
    direction;
  },
  slidesCount: 0,
  slideNumber: 0,
  items: [
    {
      url: photo1,
      title: 'датчик дифференциального давления СМП7700',
    },
  ],
});

const Slider = () => {
  const testData = [
    { title: 'Датчики давления', url: photo1 },
    {
      url: photo2,
      title: 'датчики уровня',
    },
    {
      url: photo3,
      title: 'Датчики температуры',
    },
    {
      url: photo5,
      title: 'Сенсоры давления',
    },
  ];

  const items = testData;

  const [slide, setSlide] = useState(0);

  const goToSlide = (number: number) => {
    setSlide(number % items.length);
  };

  const changeSlide = useCallback(
    (direction = 1) => {
      let slideNumber = 0;
      if (slide + direction < 0) {
        slideNumber = items.length - 1;
      } else {
        slideNumber = (slide + direction) % items.length;
      }
      setSlide(slideNumber);
    },
    [items.length, slide]
  );
  useEffect(() => {
    // if (!autoPlay) return;
    const interval = setInterval(() => {
      changeSlide(1);
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [changeSlide, items.length, slide]);

  return (
    <section className="slider">
      <div className="slider-container animated fadeInUp">
        <SliderContext.Provider
          value={{
            goToSlide,
            changeSlide,
            slidesCount: items.length,
            slideNumber: slide,
            items,
          }}
        >
          <SliderList />
          <Arrows className="main" changeSlide={changeSlide} />
          <Dots />
        </SliderContext.Provider>
      </div>
    </section>
  );
};
export default Slider;
