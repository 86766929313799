import React from 'react';
import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import postMail from '../../requests/mail';
import { RootState } from '../../store/store';
import { toast } from 'react-toastify';
import { obj, FormInputs } from '../../interfaces/types';
import './Form.css';

const styles: { [index: string]: obj } = {
  product: {
    mt: 5,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    '& .MuiTextField-root': { m: 1 },
  },
  general: {
    mt: 2,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(1, 1fr)',
    '& .MuiTextField-root': { m: 1 },
  },
  contacts: {
    mt: 2,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    '& .MuiTextField-root': { m: 1 },
  },
  button: {
    gridArea: '4 / 1 / 5 / 3',
    background: '#f73d3d',
    color: '#FFF',
    border: 'none',
    fontWeight: 'bold',
  },
};
const Form = React.forwardRef(
  ({ title, className, button }: { title: boolean; className: string; button: string }, ref) => {
    const products = useSelector((state: RootState) => state.products.products);
    const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm<FormInputs>({ mode: 'onSubmit' });

    const { id: currId } = useParams();

    const onSubmit = async (data: FormInputs) => {
      const productName = currId
        ? products.filter(({ id }) => id.toString() === currId)[0].title
        : 'Общий запрос';
      const res = await postMail({
        title: 'запрос',
        text: `email: ${data.email}, имя: ${data.name}, телефон: ${data.phone},
        компания: ${data.company}, сообщение: ${data.content}`,
        link: window.location.href,
        product: productName,
      });
      if (res.response.split(' ')[0] == '250') {
        toast('Ваше сообщение успешно отправлено');
      }
      window.ym(97314730, 'reachGoal', 'form_send');
      reset();
    };
    return (
      <div>
        <>{title && <p className="form-text">Свяжитесь с нами</p>}</>
        <Box
          id="send-inquiry"
          component="form"
          sx={styles[className]}
          onSubmit={handleSubmit(onSubmit)}
          ref={ref}
        >
          <TextField
            required
            label="Имя"
            helperText="Обязательно"
            sx={{ width: '90%' }}
            {...register('name')}
          />
          <TextField
            required
            helperText="Обязательно"
            sx={{ width: '90%' }}
            label={errors.email?.message || 'E-mail'}
            {...register('email', {
              required: true,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Введите корректный email',
              },
            })}
          />
          {className !== 'general' && (
            <>
              <TextField label="Телефон" sx={{ width: '90%' }} {...register('phone')} />
              <TextField label="Компания" sx={{ width: '90%' }} {...register('company')} />
            </>
          )}
          <TextField
            id="outlined-multiline-flexible"
            label="Сообщение"
            multiline
            maxRows={4}
            sx={{ width: '95%', gridArea: '3 / 1 / 4 / 3' }}
            {...register('content')}
          />
          <button className={'button'} type="submit" style={styles[button]}>
            отправить
          </button>
        </Box>
      </div>
    );
  }
);
export default Form;
