import Button from './Button';
import Title from './Title';

export default function Layout({
  className,
  label,
  onClick,
}: {
  className: string;
  label: string;
  onClick: () => void;
}) {
  return (
    <section className={`${className}-section`}>
      <Title className={'top'} label={label} />
      <Button className={`${className}-button`} onClick={onClick} label={'Подробнее'} />
    </section>
  );
}
