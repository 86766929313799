import React, { Fragment, useContext, useState } from 'react';
import Slide from './Slide';
import { SliderContext } from '../Slider';
import Spinner from '../../../Spinner/Spinner';

import './SliderList.css';

export default function SliderList() {
  const [imgIsLoaded, setLoaded] = useState(false);
  const { slideNumber, items } = useContext(SliderContext);
  const containerStyle = {
    minWidth: '100%',
    alignItems: 'center',
  };
  const spinnerStyle = {
    width: '200px',
    height: '200px',
  };
  return (
    <div className="slide-list" style={{ transform: `translateX(-${slideNumber * 100}%)` }}>
      {items.map((slide, index) => (
        <Fragment key={`container-${index}`}>
          {!imgIsLoaded && (
            <Spinner
              key={`spinner-${index}`}
              containerStyle={containerStyle}
              spinnerStyle={spinnerStyle}
            />
          )}
          <Slide
            key={`slide-${index}`}
            title={slide.title}
            cover={slide.url}
            setLoaded={setLoaded}
            style={imgIsLoaded ? 'block' : 'none'}
          />
        </Fragment>
      ))}
    </div>
  );
}
