import React from 'react';

export default function Title({ className, label }: { className: string; label: string }) {
  return (
    <div className={`title-container ${className}`}>
      <h2 className={`title ${className}-title`}>
        {`${label
          .split('')
          .map((letter) => letter.toUpperCase())
          .join('')}`}
      </h2>
    </div>
  );
}
