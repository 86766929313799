export default function Button({
  className,
  onClick,
  label,
}: {
  className: string;
  onClick: () => void;
  label: string;
}) {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {label}
    </button>
  );
}
